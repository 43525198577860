export const CATALOG_MAIN_LINK = 'catalog'
export const CATALOG_ITEM_LINK = 'item'
export const CART_MAIN_LINK = 'cart'
export const USER_AGREEMENT_LINK = 'user-agreement'
export const PRIVACY_POLICY_LINK = 'privacy-policy'
export const STATIC_DOCS_LINK = 'docs'
export const SEARCH_PAGE = 'search'

export const MAIN_URL = "https://app.polza.tech/public";
// export const MAIN_URL = "https://sellone-backend.cabatest.ru/public";

export const DEV_SHOP_ID = "65df553d27d27a5e51b5c1f5"



export const CURRENCY_MAP = {
    'USD': '$',  // United States Dollar
    'EUR': '€',  // Euro
    'JPY': '¥',  // Japanese Yen
    'GBP': '£',  // British Pound
    'AUD': 'A$', // Australian Dollar
    'CAD': 'C$', // Canadian Dollar
    'CHF': 'CHF', // Swiss Franc
    'CNY': '¥',  // Chinese Yuan
    'SEK': 'kr',  // Swedish Krona
    'NZD': 'NZ$', // New Zealand Dollar
    'MXN': '$',  // Mexican Peso
    'SGD': 'S$', // Singapore Dollar
    'HKD': 'HK$', // Hong Kong Dollar
    'NOK': 'kr',  // Norwegian Krone
    'KRW': '₩',  // South Korean Won
    'TRY': '₺',  // Turkish Lira
    'INR': '₹',  // Indian Rupee
    'RUB': '₽',  // Russian Ruble
    'BRL': 'R$', // Brazilian Real
    'ZAR': 'R',  // South African Rand
};


export const IMG_PLACEHOLDER_PRODUCT = 'https://app.polza.tech/get-file/public/additional_data/product-placeholder.png'
export const IMG_PLACEHOLDER_PROMO = 'https://app.polza.tech/get-file/public/additional_data/promo-placeholder.png'
export const LANDING_URL = 'https://polza.tech'

export const DEMO_PRODUCT = {
    "_id": "65ac892aca48bfd1dbd637a2",
    "name": "Демо продукт",
    "description": "Демо описание",
    "options": [
        {
            "name": "3",
            "values": [
                {
                    "value": "3",
                    "price_raise": null,
                    "in_stock": null
                },
                {
                    "value": "4",
                    "price_raise": null,
                    "in_stock": null
                }
            ]
        },
        {
            "name": "4",
            "values": [
                {
                    "value": "3",
                    "price_raise": null,
                    "in_stock": null
                }
            ]
        }
    ],
    "price_data": {
        "price": "123323",
        "discount": 2,
        "in_stock": 10
    },
    "categories": [
        {
            "_id": "65ad0632ca48bfd1dbd637d2",
            "name": "new",
            "translit_name": "new-z9qX",
            "count": 1,
            "is_final": true,
            "parents": [
                {
                    "_id": "65ad0632ca48bfd1dbd637d2",
                    "name": "new",
                    "translit_name": "new-z9qX",
                    "count": 1,
                    "is_final": true
                },
                {
                    "_id": "65acfbe4ca48bfd1dbd637ab",
                    "name": "1",
                    "translit_name": "1-cI5U",
                    "count": 8,
                    "is_final": false
                },
                {
                    "_id": "65ac86cbca48bfd1dbd63794",
                    "name": "Диван кровать",
                    "translit_name": "test-4RPc",
                    "count": 4,
                    "is_final": false
                }
            ]
        },
        {
            "_id": "65acfbecca48bfd1dbd637ad",
            "name": "test",
            "translit_name": "test-kS5l",
            "count": 2,
            "is_final": true,
            "parents": [
                {
                    "_id": "65acfbecca48bfd1dbd637ad",
                    "name": "test",
                    "translit_name": "test-kS5l",
                    "count": 2,
                    "is_final": true
                },
                {
                    "_id": "65ac86cbca48bfd1dbd63794",
                    "name": "Диван кровать",
                    "translit_name": "test-4RPc",
                    "count": 4,
                    "is_final": false
                }
            ]
        }
    ],
    "files": [
        {
            "_id": "65acf9d0ca48bfd1dbd637a5",
            "expire_at": null,
            "urls": {
                // "orig": IMG_PLACEHOLDER_PRODUCT,
                "orig": 'https://placehold.co/100x100',

            }
        }
    ]
}




export const ACTIVITY_PING_TIMEOUT = 5000

export const OWN_DELIVERY_TYPE = 'own_delivery'
export const NO_DELIVERY_TYPE = 'no_delivery'
export const DELIVERY_TYPE_VALUES =
{
    [OWN_DELIVERY_TYPE]: 'Доставка',
    [NO_DELIVERY_TYPE]: 'Самовывоз',
}
